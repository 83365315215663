import React from 'react';
import { Helmet } from 'react-helmet';
import addToMailchimp from 'gatsby-plugin-mailchimp';

const initialState = {
  email: '',
  firstName: '',
  lastName: '',
  result: null
};

const RESULT_MSG_TIMEOUT = 10000;

export default class LandinPage extends React.Component {
  state = initialState;
  handleSubscribe = async evt => {
    const { email, firstName, lastName } = this.state;
    evt.preventDefault();
    const result = await addToMailchimp(email, {
      FNAME: firstName,
      LNAME: lastName
    });
    this.setState({ ...initialState, result }, () => {
      setTimeout(() => {
        this.setState(initialState);
      }, RESULT_MSG_TIMEOUT);
    });
  };
  handleOnChange = input => evt => {
    this.setState({ [input]: evt.target.value });
  };
  renderForm = () => {
    const { email, firstName, lastName } = this.state;
    return (
      <form onSubmit={this.handleSubscribe}>
        <div className="form-container">
          <div className="row full-name-group">
            <input
              className="firstName"
              type="text"
              value={firstName}
              placeholder="First Name"
              onChange={this.handleOnChange('firstName')}
            />
            <input
              type="text"
              value={lastName}
              placeholder="Last Name"
              onChange={this.handleOnChange('lastName')}
            />
          </div>
          <div className="row email-group">
            <input
              type="email"
              value={email}
              placeholder="Email"
              onChange={this.handleOnChange('email')}
            />
          </div>
          <div className="row submit-button-container">
            <input
              type="submit"
              value="Subscribe"
              name="subscribe"
              id="mc-embedded-subscribe"
            />
          </div>
        </div>
      </form>
    );
  };
  renderResult = () => {
    const { result } = this.state;
    return <h3>{result.msg}</h3>;
  };
  render = () => {
    const { result } = this.state;
    return (
      <React.Fragment>
        <Helmet>
          <meta
            name="title"
            content="yumlendar | Plan what you eat, eat what you plan."
          />
          <meta
            name="description"
            content="Have your meal plan, generate your grocery list and invite someone to be on top of your goals. Join our invite-only beta!"
          />
          <meta
            name="keywords"
            content="yumlendar, diet app, meal planner app, meal planner, meal plan, nutritional plan, diet plan, nutrition, diet, nutritionist, coach,"
          />

          <meta
            property="og:title"
            content="yumlendar | Plan what you eat, eat what you plan."
          />
          <meta property="og:url" content="http://yumlendar.com" />
          <meta
            property="og:image"
            content="http://www.yumlendar.com/logo-yumlendar.png"
          />

          <meta name="robots" content="index, follow" />
          <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
          <meta name="language" content="English" />
          <title>yumlendar | Plan what you eat, eat what you plan.</title>
          <link rel="canonical" href="http://yumlendar.com" />
          <link
            href="https://fonts.googleapis.com/css?family=Open+Sans:400,800|Staatliches&display=swap"
            rel="stylesheet"
          />
        </Helmet>
        <div className="main-container">
          <div className="phone-container">
            <div className="phone">
              <img alt="Smartphone" src="/smartphone.png" />
              <img className="logo" alt="yumlendar" src="/logo-yumlendar.png" />
            </div>
          </div>
          <div className="copy-container">
            <h1>
              Plan <span className="outline-text">what you</span> eat,
              <br /> eat <span className="outline-text">what you</span> plan.
            </h1>
            <h2>
              Have your meal plan, generate your grocery list and invite someone
              to be on top of your goals!
            </h2>
            <h3>
              Want to know more? <br />
              Drop us your email and join our invite-only beta
            </h3>
            <div>{result ? this.renderResult() : this.renderForm()}</div>
          </div>
        </div>
      </React.Fragment>
    );
  };
}
